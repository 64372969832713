<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.vertragsart.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.vertragsart.list.title"></app-i18n>
      </h1>

      <app-vertragsart-list-toolbar></app-vertragsart-list-toolbar>
      <app-vertragsart-list-filter></app-vertragsart-list-filter>
      <app-vertragsart-list-table></app-vertragsart-list-table>
    </div>
  </div>
</template>

<script>
import VertragsartListFilter from '@/modules/vertragsart/components/vertragsart-list-filter.vue';
import VertragsartListTable from '@/modules/vertragsart/components/vertragsart-list-table.vue';
import VertragsartListToolbar from '@/modules/vertragsart/components/vertragsart-list-toolbar.vue';

export default {
  name: 'app-vertragsart-list-page',

  components: {
    [VertragsartListFilter.name]: VertragsartListFilter,
    [VertragsartListTable.name]: VertragsartListTable,
    [VertragsartListToolbar.name]: VertragsartListToolbar,
  },
};
</script>

<style>
</style>
